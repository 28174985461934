/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { useDebounceFn } from '@vueuse/core'

export enum LegalEventCategory {
  A = 'Application filing',
  B = 'Application discontinuation',
  C = 'Application revival',
  D = 'Search and examination',
  E = 'Pre-grant review request',
  F = 'IP right grant',
  G = 'Protection beyond IP right term',
  H = 'IP right cessation',
  K = 'IP right revival',
  L = 'IP right review request',
  M = 'IP right maintenance',
  // N='Permanent termination',
  P = 'Re-publication of document after modification',
  Q = 'Document publication',
  R = 'Party data change',
  S = 'Information on licensing and similar transactions',
  T = 'Administrative procedure adjustment',
  U = 'Payment',
  V = 'Appeal',
  W = 'Other',
  Y = 'Correction and deletion of event information',
  Z = 'Categorisation pending',
  undefined = 'Uncategorized',
  // Patbase Categories
  LSAL = 'Appeal',
  SAL = 'Appeal',
  LSAS = 'Reassignment',
  LSDW = 'Deemed withdrawn/Abandoned/Cancelled',
  LSES = 'Examined/Supplementary Search report issued',
  LSFE = 'Renewal Fees Paid',
  LSGT = 'Granted/Extended (Supplementary Protection Certificate)',
  LSLE = 'Lapsed/Expired/Ceased/Dead',
  LSLI = 'License',
  LSNE = 'Non-Entry into National Phase',
  LSNP = 'National Phase Entry',
  LSOP = 'Opposition Filed/Request for Revocation/Partially Amended:',
  LSPB = 'Published',
  LSRE = 'Restored/Reinstated/Amended/Partially Amended',
  LSRV = 'Revoked/Rejected/Annulled/Invalid',
  LSRX = 'Re-examination',
  LSAP = 'Applied',
  LSWD = 'Withdrawn/Abandoned/Cancelled/Surrendered/Suspended/Terminated/Void'
}

export enum IfiFields {
  applicationNumber = 'an',
  cpcClassification = 'cpcl',
  eclaClassification = 'ecla',
  earliestPriorityFilingDate = 'prid',
  familyNumber = 'fam',
  filingDate = 'ad',
  filingNumber = 'anucid',
  ifiApplicants = 'ifi_pa',
  inventors = 'inv',
  ipcrClassification = 'icl',
  numberOfClaims = 'nclms',
  publicationDate = 'pd',
  priorityFilingNumber = 'pri',
  'ttl_*' = 'ttl_*',
  'ab_*' = 'ab_*'
}

export const OPS_SEARCH_PARAMS = [
  'publicationnumber',
  'inventorandapplicant',
  'titleandabstract',
  'inventor',
  'applicant',
  'publicationdate'
] as const

export const OPS_SEARCH_OPERATORS = [
  '=',
  '<',
  '>',
  '<=',
  '>=',
  'all',
  'within',
  'any'
  // ,'and',
  // ,'/',
  // ,'prox',
  // 'distance',
  // 'high',
  // 'low',
  // 'unit'
] as const

export const YMD_DATE_FORMAT = 'yyyyMMdd' as const

export const MAX_RESULTS_RETURNED = 2_000 as const

export const PATENT_SOURCES: PatentSource[] = ['patbase']

export const patentSourceMap: Record<PatentSource, string> = {
  ifi: 'IFI Claims',
  ops: 'European Patent Office',
  patbase: 'Patbase'
}

export const patentSearchFn: Record<
  PatentSource,
  (searchParams: string[], text?: string | string[]) => string
> = {
  ops(searchParams: string[], text?: string | string[]) {
    if (!text) {
      return ''
    }
    return searchParams
      .map((param, i) => {
        const value = Array.isArray(text) ? text[i] : text
        return `${param} = "${value}"`
      })
      .join(' or ')
  },
  ifi(fields: string[], text?: string | string[]) {
    if (!text) {
      return ''
    }

    const fieldMap: Record<(typeof fields)[number], string[]> = {
      publicationnumber: ['pn'],
      inventorandapplicant: ['inv', 'ifi_pa'],
      inventor: ['inv'],
      titleandabstract: ['ttl', 'ab'],
      applicant: ['ifi_pa'],
      publicationdate: ['pd'],
      famn: ['fam']
    }
    const queries = []
    for (let i = 0; i < fields.length; i++) {
      const value = Array.isArray(text) ? text[i] : text
      for (const field of fieldMap[fields[i]] ?? []) {
        queries.push(`${field}:"${value}"`)
      }
    }
    return queries.join(' OR ')
  },
  patbase(fields: string[], text?: string | string[]) {
    if (!text) {
      return ''
    }

    const fieldMap: Record<(typeof fields)[number], string[]> = {
      publicationnumber: ['PN'],
      inventorandapplicant: ['IN', 'PA'],
      inventor: ['IN'],
      titleandabstract: ['FT', 'TA'],
      applicant: ['PA'],
      publicationdate: [],
      famn: ['FN']
    }
    const queries = []
    for (let i = 0; i < fields.length; i++) {
      const value = Array.isArray(text) ? text[i] : text
      for (const field of fieldMap[fields[i]] ?? []) {
        queries.push(`${field}=(${value})`)
      }
    }
    return queries.join(' OR ')
  }
}

export const makeSearchQuery = (
  source: PatentSource,
  searchParams: string[],
  text?: string | string[]
) => patentSearchFn[source](searchParams, text)

export const usePatentSearch = ({
  queries,
  pagination,
  debounce
}: {
  queries: globalThis.Ref<{ source: PatentSource; query: string }[]>
  pagination?: PageControls
  debounce?: number
}) => {
  const { axios, onError } = useApi()
  const patentSearch = useDebounceFn(async () => {
    if (!queries.value.length) {
      return null
    }
    const response = await axios.get<ApiList<PatentResult>>('patent-search', {
      params: { queries: queries.value, ...pagination }
    })
    return response.data
  }, debounce ?? 500)
  return useQuery({
    queryFn: async () => (await patentSearch()) || null,
    onError,
    queryKey: ['patent_search', queries, pagination]
  })
}

export const usePatbaseSearch = ({
  queries,
  pagination,
  debounce
}: {
  queries: globalThis.Ref<{ source: PatentSource; query: string }[]>
  pagination?: PageControls
  debounce?: number
}) => {
  const { axios, onError } = useApi()

  const patentSearch = useDebounceFn(async () => {
    if (!queries.value.length) {
      return null
    }
    const response = await axios.get<
      ApiList<PatebaseSearchResultBib['Families'] | null>
    >('patent-search/patbase', {
      params: { queries: queries.value, ...pagination }
    })
    return response.data
  }, debounce ?? 500)
  return useQuery({
    queryFn: async () => (await patentSearch()) || null,
    onError,
    queryKey: ['patent_search', queries, pagination]
  })
}

export const usePatentEvents = (params?: globalThis.Ref, debounce = 0) => {
  const { axios, getNextPageParam, onError } = useApi()

  const fetchPatentEvents = useDebounceFn(async (pageParam: number) => {
    const { data } = await axios.get<ApiList<PatentLegalEvent>>(
      'patent-legal-events',
      {
        params: {
          relations: ['patent'],
          page: pageParam,
          perPage: 50,
          ...params?.value
        }
      }
    )
    return data
  }, debounce)

  return useInfiniteQuery({
    queryFn: async ({ pageParam }) => await fetchPatentEvents(pageParam),
    getNextPageParam,
    onError,
    queryKey: ['patent_legal_events', params]
  })
}

export const usePatentImport = () => {
  const {
    axios,
    onError,
    refetchQuery,
    toast: { addToast }
  } = useApi()

  return useMutation({
    mutationFn: async ({
      queries,
      importAll
    }: {
      importAll: boolean
      queries: { query: string; source: PatentSource }[]
    }) => {
      const data = await Promise.all(
        queries.map(async ({ query, source }) => {
          const { data } = await axios.post<ApiList<Patent>>('patents/import', {
            query,
            source,
            importAll
          })
          return data.data
        })
      )

      refetchQuery(['workspaces'])
      refetchQuery(['patent_legal_events'])
      refetchQuery(['patents'])

      await Promise.all([
        refetchQuery(['ip_sheets']),
        refetchQuery(['patent_families'])
      ])
      addToast({ type: 'success', title: 'Patent added successfully' })
      return data.flat()
    },
    onError
  })
}

export const toEpoDocPatentNumber = (
  patent: PatentResult,
  separator?: string
) => [patent.country, patent.patentNumber, patent.kind].join(separator ?? '')
